/* Mobile*/
/* Media query for small screens */
@media screen and (max-width: 480px) {
  html {
    font-size: 50%;
  }

  body {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    color: #444;
    line-height: 1.9;
    background-color: #f3f3f3;
  }

  /* GENERAL ELEMENTS */
  .section {
    width: 100%;
    padding: 3rem 3rem;
    border-top: 1px solid #ddd;

    transition: transform 1s, opacity 1s;
  }

  .section--hidden {
    opacity: 0;
    transform: translateY(8rem);
  }

  .section__title {
    display: grid;
    align-items: center;
    justify-content: center;
    max-width: 80rem;
    margin: 0 auto 4rem auto;
  }

  .section__description {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--color-primary-darker);
    margin-bottom: 1rem;
  }

  .section__header {
    font-size: clamp(5vw, 1.9vw, 4rem);
    text-wrap: pretty;
    line-height: 1.3;
    font-weight: 500;
  }

  .btn {
    display: inline-block;
    background-color: var(--color-primary);
    font-size: 1.6rem;
    font-family: inherit;
    font-weight: 500;
    border: none;
    padding: 1.25rem 3rem;
    border-radius: 10rem;
    cursor: pointer;
    transition: all 0.3s;
  }

  .btn:hover {
    background-color: var(--color-primary-darker);
  }

  .btn--text {
    display: inline-block;
    background: none;
    font-size: 1.7rem;
    font-family: inherit;
    font-weight: 500;
    color: var(--color-primary);
    border: none;
    border-bottom: 1px solid currentColor;
    padding-bottom: 2px;
    cursor: pointer;
    transition: all 0.3s;
  }

  p {
    color: #555;
  }

  /* This is BAD for accessibility! Don't do in the real world! */
  button:focus {
    outline: none;
  }

  img {
    transition: filter 0.5s;
  }

  .lazy-img {
    filter: blur(20px);
  }

  /* HEADER */
  .header {
    width: 100%;
    height: 10vh;

    /* NAVIGATION */
    .nav {
      width: inherit;
      height: 7rem;
      display: grid;
      grid-template-columns: 1fr;
      place-items: center;
      padding: 1rem 1rem;
      z-index: 100;
    }

    /* nav and stickly class at the same time */
    .nav.sticky {
      position: fixed;
      background-color: rgba(255, 255, 255, 0.95);
    }

    .nav__logo {
      height: 4.5rem;
      transition: all 0.3s;
    }

    .nav__links {
      display: none;
    }

    .nav__link:link,
    .nav__link:visited {
      font-size: 1.7rem;
      font-weight: 400;
      color: inherit;
      text-decoration: none;
      display: block;
      transition: all 0.3s;
    }

    .nav__link--btn:link,
    .nav__link--btn:visited {
      padding: 0.8rem 2.5rem;
      border-radius: 3rem;
      background-color: var(--color-primary);
      color: #222;
    }

    .nav__link--btn:hover,
    .nav__link--btn:active {
      color: inherit;
      background-color: var(--color-primary-darker);
      color: #333;
    }
  }

  .banner__section {
    display: flex;
    align-items: center;
    padding: 0 3rem 3rem 3rem;

    .banner__title {
      width: 100%;
      text-align: center;
      h1 {
        font-size: clamp(4.8vw, 6vw, 6rem);
        line-height: 1.35;
      }
    }
  }

  h4 {
    font-size: 2.4rem;
    font-weight: 500;
  }

  .header__img {
    width: 100%;
    grid-column: 2 / 3;
    grid-row: 1 / span 4;
    transform: translateY(-6rem);
  }

  .highlight {
    position: relative;
  }

  .highlight::after {
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: 0.7;
    transform: scale(1.07, 1.05) skewX(-15deg);
    background-image: var(--gradient-primary);
  }

  /* Models */
  .model__container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding-bottom: 20px;

    .model {
      position: relative;
      display: grid;
      grid-template-rows: 1fr;
      cursor: pointer;

      .video_overlay {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 1;
        border-radius: 10px;
        z-index: 1;

        svg {
          display: flex;
          fill: rgba(255, 0, 0, 0.8);
          stroke-width: 20px;
          align-items: center;
          justify-content: center;
          height: 30vw;
          width: 100%;
          padding: 5vw;
          margin: 15vw auto;
        }
      }

      video {
        max-width: 400px;
        width: 100%;
        height: auto;
      }

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        padding-bottom: 10px;
        font-size: clamp(4vw, 1.9vw, 4rem);
        font-weight: 400;
        line-height: 1.35;
      }

      img {
        max-width: 100%;
        max-height: 220px;
        height: auto;
        margin: 0 auto;
      }
    }
  }

  .models__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary-opacity);
    height: 5.5rem;
    width: 5.5rem;
    border-radius: 50%;
    margin-bottom: 2rem;
  }

  .models__icon svg {
    height: 2.5rem;
    width: 2.5rem;
    fill: var(--color-primary);
  }

  .models__header {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  /* FEATURES */
  .features {
    max-width: 1150px;
    display: grid;
    align-self: center;
    justify-self: center;
    margin: 0 3rem;
  }

  .features__img {
    width: 100%;
    max-width: 400px;
  }

  .features__wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 4rem;
    font-size: 1.5rem;
    margin-bottom: 40px;

    .image__container {
      display: flex;
      place-content: center;
    }
  }

  .content__wrapper {
    place-content: center;
  }

  .content__text__long {
    display: none;
  }

  .content__text__short {
    visibility: visible;
  }

  .content__text_short .features__icon {
    display: none;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary-opacity);
    height: 5.5rem;
    width: 5.5rem;
    border-radius: 50%;
    margin-bottom: 2rem;
  }

  .features__icon svg {
    height: 2.5rem;
    width: 2.5rem;
    fill: var(--color-primary);
  }

  .features__header {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  /* OPERATIONS */
  .operations {
    max-width: 100rem;
    margin: 12rem auto 0 auto;

    background-color: #fff;
  }

  .operations__tab-container {
    display: flex;
    justify-content: center;
  }

  .operations__tab {
    margin-right: 0.5rem;
    transform: translateY(-50%);
  }

  .operations__tab span {
    margin-right: 1rem;
    font-weight: 600;
    display: inline-block;
  }

  .operations__tab--1 {
    background-color: var(--color-secondary);
  }

  .operations__tab--1:hover {
    background-color: var(--color-secondary-darker);
  }

  .operations__tab--3 {
    background-color: var(--color-tertiary);
    margin: 0;
  }

  .operations__tab--3:hover {
    background-color: var(--color-tertiary-darker);
  }

  .operations__tab--active {
    transform: translateY(-66%);
  }

  .operations__content {
    display: none;

    /* JUST PRESENTATIONAL */
    font-size: 1.7rem;
    padding: 2.5rem 7rem 6.5rem 7rem;
  }

  .operations__content--active {
    display: grid;
    grid-template-columns: 7rem 1fr;
    column-gap: 3rem;
    row-gap: 2rem;
  }

  .operations__header {
    text-wrap: pretty;
    font-size: 1.9rem;
    font-weight: 500;
    align-self: center;
    margin-right: -15px;
  }

  .operations__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 7rem;
    width: 7rem;
    border-radius: 50%;
  }

  .operations__icon svg {
    height: 2.75rem;
    width: 2.75rem;
  }

  .operations__content p {
    text-align: justify;
    grid-column: 1 / -1;
  }

  .operations__icon--1 {
    background-color: var(--color-secondary-opacity);
  }
  .operations__icon--2 {
    background-color: var(--color-primary-opacity);
  }
  .operations__icon--3 {
    background-color: var(--color-tertiary-opacity);
  }
  .operations__icon--1 svg {
    fill: var(--color-secondary-darker);
  }
  .operations__icon--2 svg {
    fill: var(--color-primary);
  }
  .operations__icon--3 svg {
    fill: var(--color-tertiary);
  }

  /* SLIDER */
  .slider {
    max-width: 100rem;
    height: 50rem;
    margin: 0 auto;
    position: relative;

    /* IN THE END */
    overflow: hidden;
  }

  .slide {
    position: absolute;
    top: 0;
    width: 100%;
    height: 50rem;

    display: flex;
    align-items: center;
    justify-content: center;

    /* THIS creates the animation! */
    transition: transform 1s;
  }

  .slide > img {
    /* Only for images that have different size than slide */
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .slider__btn {
    position: absolute;
    top: 50%;
    z-index: 10;

    border: none;
    background: rgba(255, 255, 255, 0.7);
    font-family: inherit;
    color: #333;
    border-radius: 50%;
    height: 5.5rem;
    width: 5.5rem;
    font-size: 3.25rem;
    cursor: pointer;
  }

  .slider__btn--left {
    left: 6%;
    transform: translate(-50%, -50%);
  }

  .slider__btn--right {
    right: 6%;
    transform: translate(50%, -50%);
  }

  .dots {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }

  .dots__dot {
    border: none;
    background-color: #b9b9b9;
    opacity: 0.7;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    margin-right: 1.75rem;
    cursor: pointer;
    transition: all 0.5s;

    /* Only necessary when overlying images */
    /* box-shadow: 0 0.6rem 1.5rem rgba(0, 0, 0, 0.7); */
  }

  .dots__dot:last-child {
    margin: 0;
  }

  .dots__dot--active {
    /* background-color: #fff; */
    background-color: #555;
    opacity: 1;
  }

  /* TESTIMONIALS */
  .testimonial {
    width: 65%;
    position: relative;
  }

  .testimonial::before {
    content: "\201C";
    position: absolute;
    top: -5.7rem;
    left: -6.8rem;
    line-height: 1;
    font-size: 20rem;
    font-family: inherit;
    color: var(--color-primary);
    z-index: -1;
  }

  .testimonial__header {
    font-size: 2.25rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }

  .testimonial__text {
    font-size: 1.7rem;
    margin-bottom: 3.5rem;
    color: #555;
  }

  .testimonial__author {
    margin-left: 3rem;
    font-style: normal;

    display: grid;
    grid-template-columns: 6.5rem 1fr;
    column-gap: 2rem;
  }

  .testimonial__photo {
    grid-row: 1 / span 2;
    width: 6.5rem;
    border-radius: 50%;
  }

  .testimonial__name {
    font-size: 1.7rem;
    font-weight: 500;
    align-self: end;
    margin: 0;
  }

  .testimonial__location {
    font-size: 1.5rem;
  }

  .section__title--testimonials {
    margin-bottom: 4rem;
  }

  /* SIGNUP */
  .section--sign-up {
    background-color: #37383d;
    border-top: none;
    border-bottom: 1px solid #444;
    text-align: center;
    padding: 8rem 3rem 0 3rem;
  }

  .section--sign-up .section__header {
    color: #fff;
    text-align: center;
  }

  .section--sign-up .section__title {
    margin-bottom: 6rem;
  }

  .section--sign-up .btn {
    font-size: 1.9rem;
    padding: 2rem 5rem;
  }

  /* FOOTER */
  .footer__nav {
    list-style: none;
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;
  }

  .footer__item {
    margin-right: 4rem;
  }

  .footer__link {
    font-size: 1.6rem;
    color: #eee;
    text-decoration: none;
  }

  .footer__logo {
    height: 5rem;
    display: block;
    margin: 0 auto;
    margin-bottom: 5rem;
  }

  .footer__copyright {
    font-size: 1.4rem;
    color: #aaa;
    text-align: center;
  }

  .footer__copyright .footer__link {
    font-size: 1.4rem;
  }

  /* MODAL WINDOW */
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 60rem;
    background-color: #f3f3f3;
    padding: 5rem 6rem;
    box-shadow: 0 4rem 6rem rgba(0, 0, 0, 0.3);
    z-index: 1000;
    transition: all 0.5s;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    z-index: 100;
    transition: all 0.5s;
  }

  .modal__header {
    font-size: 3.25rem;
    margin-bottom: 4.5rem;
    line-height: 1.5;
  }

  .modal__form {
    margin: 0 3rem;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    gap: 2.5rem;
  }

  .modal__form label {
    font-size: 1.7rem;
    font-weight: 500;
  }

  .modal__form input {
    font-size: 1.7rem;
    padding: 1rem 1.5rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
  }

  .modal__form button {
    grid-column: 1 / span 2;
    justify-self: center;
    margin-top: 1rem;
  }

  .btn--close-modal {
    font-family: inherit;
    color: inherit;
    position: absolute;
    top: 0.5rem;
    right: 2rem;
    font-size: 4rem;
    cursor: pointer;
    border: none;
    background: none;
  }

  .hidden {
    visibility: hidden;
    opacity: 0;
  }

  /* COOKIE MESSAGE */
  .cookie-message {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    background-color: white;
    color: #bbb;
    font-size: 1.5rem;
    font-weight: 400;
  }
}
