@import "desktop_responsive.css";
@import "tablet_responsive.css";
@import "tablet_mini_responsive.css";
@import "mobile_responsive.css";

:root {
  --color-primary: #5dd8e1;
  --color-secondary: #e5e559;
  --color-tertiary: #54e19a;
  --color-primary-darker: #55cad3;
  --color-secondary-darker: #d8d850;
  --color-tertiary-darker: #4dd792;
  --color-primary-opacity: #5dd8e137;
  --color-secondary-opacity: #ffcd0331;
  --color-tertiary-opacity: #54e19a37;
  --gradient-primary: linear-gradient(to top left, #3982b3, #5dd8e1);
  --gradient-secondary: linear-gradient(to top left, #ffb003, #ffcb03);

  --model-img-padding: 6rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.background {
  opacity: 0.5;
  background-image: url("./src/img/durability_bg.jpg");
  background-size: cover; /* Adjusts the image to cover the entire background */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents tiling */
}

.product__wrapper {
  .model {
    height: 100%;
  }
}

.buy-button {
  margin: 20px;
  text-align: center;
}

.social-icon {
  display: inline;
  width: min-content;
  margin: 0 auto;
  color: whitesmoke; /* Icon color */
  font-size: 24px; /* Icon size */
  transition: color 0.3s ease;
  text-decoration: none;
  padding: 5px 20px; /* Add some padding to increase clickable area */
}

.social-icon:hover {
  color: #e1306c; /* Instagram brand color on hover */
}

/* FOOTER */
.footer {
  p {
    height: 5.5rem;
    font-size: 3rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #555;
    background-color: whitesmoke;
    transition: all 0.3s;
    margin: 30px 0 30px 35px;
    span {
      font-size: 1.2rem;
    }
  }
}
